<template>
  <div>
    <shipblu-table :data="allSupplies" multiple v-model="selected">
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Supplies')}}: {{$t('Quantity')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'finance'">{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :style="`color: ${getStyle(data[indextr])}`">
            <div v-for="item in data[indextr].supply_request_items.filter(item => item.count > 0)" :key="item.index">
              <span class="text-darkblue" v-if="item.package_size && item.package_size.short_code != 'OSZ'">{{item.package_size.short_code}}: {{item.count}}</span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].merchant">
            {{ data[indextr].merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <template>
              <vx-tooltip position="left" :text="data[indextr].supply_request_items.map(item => item.package_size && item.package_size.short_code != 'OSZ' ? `${item.package_size.short_code}: ${item.count_delivered}` : '').filter(element => element !== '').join('\n')">
                <div class="m-auto flex items-center justify-center font-semibold" >
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ data[indextr].status }}</span>
                </div>
              </vx-tooltip>
            </template>
          </shipblu-td>
          <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'finance'" :data="data[indextr].status">
            <div @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer mr-4 mb-4"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    :disabled="data[indextr].status === 'delivered' || data[indextr].status === 'delivery_attempted'"
                    @click="editQuantity(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="CheckCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Actual Quantity')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="suppliesTotalRows" :currentPage="suppliesCurrentPage" @suppliesCurrentPage="currentPage = $event" />
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['allSupplies', 'suppliesTotalRows', 'suppliesCurrentPage'],
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: []
    }
  },
  watch: {
    suppliesCurrentPage (val) {
      this.$emit('suppliesCurrentPage', val)
    }
  },
  methods: {
    removeAttribute (event) {
      event.preventDefault()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getSupplyStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    editQuantity (supply) {
      this.$emit('supplyModal', true)
      this.$emit('supply', supply)
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  }
}
</script>